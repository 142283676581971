<template>
  <div id="TestUserPage">
    <div
      class="NavbarContainer"
      style="position: relative; padding: 50px 120px 32px 120px;"
    >
      <NewNavbar />
    </div>
    <div class="HiddentProjectsList">
      <div class="ProjectListHeader">
        <h2>{{ this.TestData?.name }}</h2>
        <h6>{{ this.TestData?.description }}</h6>
      </div>

      <div class="SetupBlock BlockColumn1">
        <div class="Timer">Оставшееся время {{ getHumanTime }}</div>
        <CardCheckUser
          v-if="SelectedQuestion != null && !showResult"
          ref="QuestionCard"
          :QuestionsList="QuestionList"
          :QuestionData="SelectedQuestion"
          :ChangeQuestion="changeQuestionWithoutData"
          :AnswersList="AnswersList"
          :CanChangePrevious="TestData.previous == 1"
          :key="`${SelectedQuestion.id}${SelectedQuestion.ItemIndex}`"
          :StartTimeValue="getHumanTime"
          :SaveData="setAnswersForAttempt"
          :EndTest="TestEnd"
        />
        <CardCheckResult
          v-if="showResult"
          :ResultCardData="ResultData"
          :ResultQuestionsList="ResultQuestions"
          :StartNewTest="OneMoreAttempt"
        />
        <div class="Footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NewNavbar from "@/components/Navbar/NavbarCombined.vue";
import CardCheckUser from "@/components/TestingUnit/CardCheckUser.vue";
import CardCheckResult from "@/components/TestingUnit/CardCheckResult.vue";

import { mapGetters } from "vuex";

import {
  getTestUserData,
  findOrCreateUserAttempt,
  findUserAttempt,
  setTestAttemptAnswers,
  getTestAttemptAnswers,
  closeTestAttempt,
  getTestResult,
  getAtemptByID,
} from "@/api/user_tests.js";

import { createSolution, setTestStatus } from "@/api/solution.js";

export default {
  components: {
    CardCheckUser,
    CardCheckResult,
    NewNavbar,
  },
  async created() {
    this.TestID = this.$route.params?.id;

    if (this.$route.params?.attempt_id != null) {
      //Если ссылка - получение результата, а не сам тест
      this.AttemptID = this.$route.params?.attempt_id;

      let _attempt_resp = await findUserAttempt(this.AttemptID);
      this.UserAttempt = _attempt_resp.data;

      let _test_result_resp = await getTestResult(this.AttemptID, this.TestID);

      this.getSortedResultList(
        _test_result_resp.data,
        this.UserAttempt.QAttempt.randomize == 1
      );

      this.ResultData = {
        time: this.getResultTime(
          this.UserAttempt.fact_finish,
          this.UserAttempt.createdAt
        ),
        success_limit: this.UserAttempt.QAttempt.success_limit,
      };

      this.showResult = true;
      return 0;
    }

    if (this.TestID == null || this.getUser?.id == null) {
      this.$router.push("/");
    }

    let _test_resp = await getTestUserData(this.TestID);
    if (_test_resp.data.status != "success") {
      this.$router.push("/");
    }

    this.TestData = _test_resp.data.data;

    let _attempt_resp = await findOrCreateUserAttempt(
      this.TestID,
      this.getUser?.id
    );
    this.UserAttempt = _attempt_resp.data;

    let _attempt_answers_resp = await getTestAttemptAnswers(
      this.UserAttempt.id
    );

    for (let i of _attempt_answers_resp.data) {
      this.AnswersList.push({
        id: i.answer_id,
        type: null,
        text_value: i.text_value,
        isReadyAnswer: true,
      });
    }

    this.createQuestionList(
      this.TestData.randomize == 1,
      this.TestData.previous == 1
    );

    let self = this;
    this.Timer = setInterval(() => {
      self.TimeLogic();
    }, 1000);
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      AttemptID: null,
      TestID: null,
      TestData: null,
      UserAttempt: null,

      Timer: null,
      SecondsBeforeEnd: 0,

      QuestionList: [],
      SelectedQuestion: null,
      AnswersList: [],

      showResult: false,

      ResultQuestions: [],
      ResultData: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    getUser() {
      return this?.user;
    },

    getHumanTime() {
      const hours = Math.floor(this.SecondsBeforeEnd / 3600);
      const minutes = Math.floor((this.SecondsBeforeEnd - hours * 3600) / 60);
      const remainingSeconds =
        this.SecondsBeforeEnd - hours * 3600 - minutes * 60;
      const timeString = `${hours
        .toString()
        .padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
      return timeString;
    },
  },
  methods: {
    getResultTime(finish_time, start_time) {
      let dateAttempStarted = new Date(start_time);
      let dateEnd = new Date(finish_time);

      let diffInSeconds =
        (dateEnd.getTime() - dateAttempStarted.getTime()) / 1000;

      console.log(diffInSeconds);
      return this.getHumanTimeForResult(diffInSeconds);
    },

    getHumanTimeForResult(SecondsBeforeEnd) {
      const hours = Math.floor(SecondsBeforeEnd / 3600);
      const minutes = Math.floor((SecondsBeforeEnd - hours * 3600) / 60);
      const remainingSeconds = SecondsBeforeEnd - hours * 3600 - minutes * 60;
      const timeString = `${hours
        .toString()
        .padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;

      return timeString;
    },

    TimeLogic() {
      let dateNow = new Date();
      dateNow = new Date(
        dateNow.getUTCFullYear(),
        dateNow.getUTCMonth(),
        dateNow.getUTCDate(),
        dateNow.getUTCHours(),
        dateNow.getUTCMinutes(),
        dateNow.getUTCSeconds()
      );

      let dateAttempStarted = new Date(this.UserAttempt.createdAt);
      dateAttempStarted = new Date(
        dateAttempStarted.getUTCFullYear(),
        dateAttempStarted.getUTCMonth(),
        dateAttempStarted.getUTCDate(),
        dateAttempStarted.getUTCHours(),
        dateAttempStarted.getUTCMinutes(),
        dateAttempStarted.getUTCSeconds()
      );

      let dateEnd = new Date(dateAttempStarted);
      dateEnd = dateEnd.setMinutes(
        dateEnd.getMinutes() + this.TestData.duration_time
      );

      let diffInSeconds = Math.round((dateEnd - dateNow.getTime()) / 1000);

      if (diffInSeconds <= 0) {
        this.TestEnd();
        return 0;
      }

      this.SecondsBeforeEnd = diffInSeconds;
      if (this.$refs.QuestionCard != null) {
        this.$refs.QuestionCard.$data.TimerCount = this.getHumanTime;
      }
    },

    shuffleQuestionList(arr, seed) {
      let j;
      for (let i = 0; i < arr.length; i++) {
        j = (seed = (seed * this.TestID) % 2147483647) % arr.length;
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      return arr;
    },

    createQuestionList(randomizeList, canPrevious) {
      let updatedQQuiz = this.TestData.QQuiz.map((quiz) => {
        const { order_number } = quiz.Order_QQustion[0];
        return {
          id: quiz.id,
          question_id: quiz.question_id,
          ["Order_QQustion.order_number"]: order_number,
          Question_QQuestion: {
            id: quiz.Question_QQuestion.id,
            title: quiz.Question_QQuestion.title,
            description: quiz.Question_QQuestion.description,
            ["TQuestion.name"]: quiz.Question_QQuestion.TQuestion.name,
            AQuestion: quiz.Question_QQuestion.AQuestion,
          },
        };
      });

      updatedQQuiz = updatedQQuiz.sort((a, b) => {
        const orderA = a["Order_QQustion.order_number"];
        const orderB = b["Order_QQustion.order_number"];
        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });

      if (randomizeList) {
        let dateAttempStarted = new Date(this.UserAttempt.createdAt);
        updatedQQuiz = this.shuffleQuestionList(
          updatedQQuiz,
          dateAttempStarted.getTime()
        );
      }

      let c = 0;
      for (let i of updatedQQuiz) {
        i.ItemIndex = c;
        i.CanModifyAfterReady = canPrevious;

        for (let answer of i.Question_QQuestion.AQuestion) {
          answer.selected = false;
          answer.textValue = "";
        }

        c++;
      }

      this.QuestionList = updatedQQuiz;

      let latestQuestionNotAnsweredIndex = 0;
      for (let quest of updatedQQuiz) {
        let questionHaveResult = false;

        for (let QAnsw of quest.Question_QQuestion.AQuestion) {
          let find = this.AnswersList.findIndex((item) => {
            console.log(
              `${QAnsw.id} - ${item.id} = ${parseInt(item.id) ===
                parseInt(QAnsw.id)}`
            );
            return parseInt(item.id) === parseInt(QAnsw.id);
          });

          if (find != -1) {
            questionHaveResult = true;
          }
        }

        if (!questionHaveResult) {
          this.SelectedQuestion = updatedQQuiz[latestQuestionNotAnsweredIndex];
          return 0;
        }

        if (quest.ItemIndex + 1 == updatedQQuiz.length) {
          this.SelectedQuestion = updatedQQuiz[latestQuestionNotAnsweredIndex];
          return 0;
        }

        latestQuestionNotAnsweredIndex++;
      }
    },

    getSortedResultList(rawList, randomize) {
      let updatedQQuiz = rawList.sort((a, b) => {
        const orderA = a["Order_QQustion.order_number"];
        const orderB = b["Order_QQustion.order_number"];
        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });

      if (randomize) {
        let dateAttempStarted = new Date(this.UserAttempt.createdAt);
        updatedQQuiz = this.shuffleQuestionList(
          updatedQQuiz,
          dateAttempStarted.getTime()
        );
      }

      this.ResultQuestions = updatedQQuiz;
    },

    changeQuestionWithoutData(questionSelected) {
      this.SelectedQuestion = questionSelected;
    },

    async setAnswersForAttempt() {
      let DataForBackend = [];

      for (let answ of this.AnswersList) {
        DataForBackend.push({
          text_value: answ.text_value,
          attempt_id: this.UserAttempt.id,
          answer_id: answ.id,
        });
      }

      await setTestAttemptAnswers(this.UserAttempt.id, DataForBackend);
    },

    async TestEnd() {
      // this.setAnswersForAttempt();

      let timeNow = new Date();
      timeNow = new Date(
        timeNow.getUTCFullYear(),
        timeNow.getUTCMonth(),
        timeNow.getUTCDate(),
        timeNow.getUTCHours(),
        timeNow.getUTCMinutes(),
        timeNow.getUTCSeconds()
      );
      timeNow = timeNow.setMinutes(timeNow.getMinutes());

      let planEnd = new Date(this.UserAttempt.createdAt);
      planEnd = new Date(
        planEnd.getUTCFullYear(),
        planEnd.getUTCMonth(),
        planEnd.getUTCDate(),
        planEnd.getUTCHours(),
        planEnd.getUTCMinutes(),
        planEnd.getUTCSeconds()
      );
      planEnd = planEnd.setMinutes(
        planEnd.getMinutes() + this.TestData.duration_time
      );

      let date = null;
      if (timeNow - planEnd > 0) {
        // planEnd
        date = "plan";
      } else {
        // timeNow
        date = "now";
      }

      await closeTestAttempt(this.UserAttempt.id, { closeTime: date });

      let attempt_result_resp = await getAtemptByID(this.UserAttempt.id);

      let taskData = {
        solutionData: {
          description: "Выполнение тестирования",
          comment: "",
          url_file: null,
          url_file_for_delete: null,
          check_date: null,
          start_date: null,
          deadline: null,
          fact: null,
          status_id: 1,
          user_id: this.user.id,
          task_id: this.$route.params?.task_id,
        },
        checkData: {
          level_id: this.$route.params?.level_id,
        },
        cellData: {
          status: "",
          task_id: this.$route.params?.task_id,
          level_id: this.$route.params?.level_id,
          user_id: this.user.id,
          extern_id: null,
        },
      };

      if (attempt_result_resp.data.score < this.TestData.success_limit) {
        taskData.solutionData.comment = "";
        taskData.solutionData.status_id = 5;
      }

      // await createSolution(formData);
      await setTestStatus(taskData);

      window.location.replace(
        `/beta/tests/${this.TestID}/task/${this.$route.params?.level_id}/${this.$route.params?.task_id}/${this.UserAttempt.id}`
      );
    },

    async OneMoreAttempt() {
      window.location.replace(
        `/beta/tests/${this.TestID}/task/${this.$route.params?.level_id}/${this.$route.params?.task_id}`
      );
    },

    async GetTestResult() {},
  },
};
</script>

<style scoped>
body {
  font-family: var(--mdb-font-roboto);
  line-height: 1.6;
  color: #4f4f4f;
  height: 100%;
  width: 100%;
  display: flex;
}

#TestUserPage {
  position: relative;
  width: 100%;
  height: 100vh;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  display: flex;
  flex-direction: row;

  overflow-y: scroll;
  overflow-x: hidden;

  background-image: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto 120%;
}
.Timer {
  display: none;
  box-sizing: border-box;
  background: rgba(31, 30, 72, 0.8);
  border-radius: 20px;
  /* width: 95%; */
  height: -moz-fit-content;
  height: fit-content;
  position: fixed;
  overflow: hidden;
  padding: 20px 35px;
  color: #ffffff;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.Footer {
  display: flex;
  margin: 30px;
  height: 30px;
  width: auto;
}
.HiddentProjectsList {
  position: relative;

  left: 0px;

  width: 80vw;
  height: auto;

  margin: 70px auto 70px auto;
  padding: 1% 3% 0% 3%;

  display: flex;
  justify-content: flex-start;

  flex-direction: column;
}

.ProjectListHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #ffffff;
  font-size: 32px;
  margin-bottom: 30px;
  width: 100%;
}

.BlockColumn1 {
  display: flex;
  flex-direction: column;
}

.SetupBlock {
  display: flex;
  gap: 20px;
}
</style>

<style scoped>
/*Стили для первого блока с настройкой теста*/
.SetupTest {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: rgba(31, 30, 72, 0.8);
  border-radius: 20px;
  width: auto;
  height: fit-content;
  position: relative;
  overflow: hidden;
  padding: 30px;
  gap: 32px;
}

.SetupTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  gap: 15px;
}

.SetupTextPoint {
  color: #ffffff;
  font-size: 16px;
}

.SetupDirection {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

/*Чекбокс*/

.SetupCheck {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  gap: 20px;
}

.SetupCheckText {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.real-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: #f8f7f700;
  border: 1px solid #312e57;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/*Состояния чекбокса*/

.real-checkbox:checked + .custom-checkbox {
  /* background-image: url("./../../assets/img/setup/vector.svg"); */
  background-position: center;
}

/*Выбор направления*/
.Competence {
  font-size: 15px;
}

.Competence > span {
  margin-right: 5px;
}

.TestName {
  display: flex;
  background: var(
    --input,
    linear-gradient(
      269deg,
      rgba(41, 37, 88, 0.54) 0%,
      rgba(38, 35, 83, 0.87) 40%,
      rgba(40, 38, 89, 0.5) 100%
    )
  );
  border-radius: 4px;
  border-style: solid;
  border-color: #413e74;
  border-width: 1px;
  padding: 12px;
  width: 48px;
  height: 48px;
  top: 30px;

  color: #667080;
  text-align: left;
  font: 400 20px/22px "Inter", sans-serif;
  -moz-appearance: textfield;
}
.TestName::-webkit-inner-spin-button {
  display: none;
}

/*Стили кнопок*/
.Button {
  height: auto;
  width: 100%;
}
</style>

<style scoped>
@media (max-width: 479px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .HiddentProjectsList {
    width: auto;
    margin: 70px 8px;
    padding: 0%;
  }
  .Timer {
    display: flex;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .HiddentProjectsList {
    width: auto;
    margin: 70px 30px;
    padding: 0%;
  }
  .Timer {
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .HiddentProjectsList {
    width: auto;
    margin: 70px 30px;
    padding: 0%;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .HiddentProjectsList {
    width: auto;
    margin: 70px 30px;
    padding: 0%;
  }
}

@media (min-width: 1400px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
}
</style>
