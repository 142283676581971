<template>
  <div class="CardCheckUser">
    <div class="LeftFloat">
      <div
        class="QuestInfo ql-snow"
        :key="QuestionData.Question_QQuestion.title"
      >
        <span class="SetupTextPoint TextSizeAuto"
          >Вопрос {{ QuestionData.ItemIndex + 1 }}</span
        >
        <span
          class="SetupTextPoint ql-editor"
          v-html="QuestionData.Question_QQuestion.title"
        ></span>
      </div>
      <span class="SetupVariableText">{{ getChoosingText() }}</span>
      <div class="AddVariable">
        <template
          v-if="
            QuestionData.Question_QQuestion['TQuestion.name'] != 'Текстовый'
          "
        >
          <div
            class="SetupCheck"
            v-for="(answer, indx) in QuestionData.Question_QQuestion.AQuestion"
            :key="`${answer.id}${indx}${getAnswer(answer.id)?.id}`"
          >
            <label class="SetupCheckText noselect">
              <input
                :type="
                  QuestionData.Question_QQuestion['TQuestion.name'] !=
                  'Одиночный'
                    ? 'checkbox'
                    : 'radio'
                "
                :key="getAnswer(answer.id)?.id"
                :checked="getAnswer(answer.id)?.id == null ? false : true"
                @click.prevent="
                  getAnswer(answer.id)?.id == null
                    ? select(answer.id, '')
                    : unselect(answer.id)
                "
                class="real-checkbox"
              />
              <span
                :class="
                  QuestionData.Question_QQuestion['TQuestion.name'] !=
                  'Одиночный'
                    ? 'custom-checkbox'
                    : 'custom-radio'
                "
              ></span>
              <span>{{ answer.answer }}</span>
            </label>
          </div>
        </template>
        <template v-else>
          <input
            type="text"
            :value="
              getAnswer(QuestionData.Question_QQuestion.AQuestion[0].id) != null
                ? getAnswer(QuestionData.Question_QQuestion.AQuestion[0].id)
                    .text_value
                : ''
            "
            @input="
              select(
                QuestionData.Question_QQuestion.AQuestion[0].id,
                $event.target.value
              )
            "
            style="position: relative; width: 100%;"
          />
        </template>
      </div>
      <div class="PrevBackButtons">
        <ButtonGradientBorder
          v-if="
            QuestionData.CanModifyAfterReady && QuestionData.ItemIndex !== 0
          "
          class="ButtonBorder"
          ButtonText="Предыдущий вопрос"
          @click="changeQuestionToPrevByItemIndex"
        />
        <ButtonGradient
          v-if="QuestionData.ItemIndex !== QuestionsList.length - 1"
          class="ButtonColor"
          ButtonText="Следующий вопрос"
          :style="
            `${canGoToNextQuestion ? '' : 'opacity: 0.5; cursor: default;'}`
          "
          @click="canGoToNextQuestion ? changeQuestion() : null"
        />
      </div>
      <div class="PrevBackButtonsMobile">
        <ButtonGradientBorder class="ButtonColor" ButtonText="Следующий" />
        <ButtonGradient class="ButtonBorder" ButtonText="Вернуться" />
      </div>
    </div>
    <div class="RightFloat">
      <div class="UpperContent">
        <span class="SetupTextPoint TextSizeAuto"
          >Оставшееся время {{ TimerCount }}</span
        >
        <span class="SetupVariableText VariableTextSize"
          >Решать тест можно в любом порядке
          {{
            CanChangePrevious == true
              ? "(Можно возвращаться к ответам)"
              : "(Ответ нельзя просматривать и изменять)"
          }}</span
        >
        <div class="AllTestTasks noselect">
          <div
            class="TaskVar"
            v-for="(question, indx) in QuestionsList"
            :key="`${indx}${question.ItemIndex}`"
            @click="
              QuestionData.CanModifyAfterReady &&
              canChooseQuestion(question, true)
                ? changeQuestionToPrev(question)
                : null
            "
          >
            <div
              :class="
                `InactiveCell ${
                  QuestionData.ItemIndex == question.ItemIndex
                    ? 'HalfActiveCell'
                    : getQuestionStatus(question)
                }`
              "
              :style="` ${getQuestionBlockStyle(question.ItemIndex, question)}`"
            >
              <span :class="`SetupTextPoint TextSizeAuto TasksNum `">{{
                question.ItemIndex + 1
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="BottomContent">
        <ButtonGradient
          class="ButtonBorderEnd"
          ButtonText="Завершить тестирование"
          @click.native="EndTest"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "@vueup/vue-quill/dist/vue-quill.snow.css";

//Если нам нужно отображение результата редактора
//Работает и без первого импорта, но требует тестирования
import "@vueup/vue-quill/dist/vue-quill.core.prod.css";
import "@vueup/vue-quill/dist/vue-quill.snow.prod.css";

import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

export default {
  props: {
    CanChangePrevious: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    QuestionData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    QuestionsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    AnswersList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    ChangeQuestion: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    StartTimeValue: {
      type: String,
      default: () => {
        return "00:00:00";
      },
    },
    SaveData: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    EndTest: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
  },
  components: {
    ButtonGradient,
    ButtonGradientBorder,
  },
  computed: {
    getAvailableCheckCounter() {
      /*
      1-x - кол-во доступных для отметки "галочек"
      0 - нельзя ничего добавить, только убрать
      -1 - неограниченное кол-во доступных для отметки "галочек"
      */

      if (
        this.QuestionData.Question_QQuestion["TQuestion.name"] != "Одиночный"
      ) {
        return -1;
      }

      let c = 0;
      for (let i of this.QuestionData.Question_QQuestion.AQuestion) {
        let find = this.SelectedAnswers.findIndex((item) => item.id === i.id);

        if (find != -1) {
          c++;
        }
      }

      return 1 - c;
    },

    canGoToNextQuestion() {
      if (
        this.QuestionData.Question_QQuestion["TQuestion.name"] == "Текстовый"
      ) {
        if (
          this.getAnswer(
            this.QuestionData.Question_QQuestion.AQuestion[0].id
          ) == null ||
          this.getAnswer(this.QuestionData.Question_QQuestion.AQuestion[0].id)
            .text_value == ""
        ) {
          return false;
        } else {
          return true;
        }
      }

      let c = 0;
      for (let i of this.QuestionData.Question_QQuestion.AQuestion) {
        let find = this.SelectedAnswers.findIndex((item) => item.id === i.id);

        if (find != -1) {
          c++;
        }
      }

      if (c == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      TimerCount: "00:00:00",
      SelectedAnswers: [],
    };
  },
  methods: {
    getChoosingText() {
      switch (this.QuestionData.Question_QQuestion["TQuestion.name"]) {
        case "Одиночный": {
          return "Выберите один вариант ответа";
        }
        case "Множественный": {
          return "Выберите несколько (в том числе 1) вариантов ответа";
        }
        case "Текстовый": {
          return "Напишите ответ";
        }
      }
    },

    getAnswer(answer_id) {
      const obj = this.SelectedAnswers.find((item) => item.id === answer_id);
      if (!obj) {
        return null;
      }

      return obj;
    },

    select(answer_id, text_value) {
      let isText =
        this.QuestionData.Question_QQuestion["TQuestion.name"] == "Текстовый";

      //Если это одиночный выбор и больше выбрать нельзя - удаляем все ответы (предыдущий) и добавляем на который жмакнули
      if (this.getAvailableCheckCounter == 0) {
        for (let i of this.QuestionData.Question_QQuestion.AQuestion) {
          if (i.id == answer_id) {
            continue;
          }

          let find = this.SelectedAnswers.findIndex((item) => item.id === i.id);

          if (find != -1) {
            this.unselect(i.id);
          }
        }
      }

      if (isText) {
        const index = this.SelectedAnswers.findIndex(
          (item) => item.id === answer_id
        );
        if (index !== -1) {
          this.SelectedAnswers[index].text_value = text_value;

          this.SaveData();
          return 0;
        }
      }

      this.SelectedAnswers.push({
        id: answer_id,
        type: isText ? "text" : null,
        text_value: isText ? text_value : "",
        isReadyAnswer: false,
      });

      this.SaveData();
    },

    unselect(idToRemove) {
      const indexToRemove = this.SelectedAnswers.findIndex(
        (item) => item.id === idToRemove
      );
      if (indexToRemove !== -1) {
        this.SelectedAnswers.splice(indexToRemove, 1);
      }

      this.SaveData();
    },

    changeQuestion() {
      this.SaveData();

      if (this.QuestionData.ItemIndex !== this.QuestionsList.length - 1) {
        let nextQuestion = this.QuestionsList.find(
          (item) => item.ItemIndex == this.QuestionData.ItemIndex + 1
        );
        this.ChangeQuestion(nextQuestion);
      }
    },
    changeQuestionToPrev(question) {
      this.SaveData();
      this.ChangeQuestion(question);
    },
    changeQuestionToPrevByItemIndex() {
      this.SaveData();
      if (this.QuestionData.ItemIndex !== 0) {
        let prevQuestion = this.QuestionsList.find(
          (item) => item.ItemIndex == this.QuestionData.ItemIndex - 1
        );
        this.ChangeQuestion(prevQuestion);
      }
    },

    getQuestionStatus(question) {
      if (this.canChooseQuestion(question)) {
        return "ActiveCell";
      }

      let c = 0;
      for (let i of question.Question_QQuestion.AQuestion) {
        let find = this.SelectedAnswers.findIndex((item) => item.id === i.id);

        if (find != -1) {
          c++;
        }
      }

      if (c == 0) {
        return "";
      } else {
        return "ActiveCell";
      }
    },

    getQuestionBlockStyle(questionItemIndex, question) {
      if (this.canChooseQuestion(question, false)) {
        return "";
      }

      if (questionItemIndex != 0) {
        let findPrevQuestion = this.QuestionsList.find(
          (x) => x.ItemIndex == questionItemIndex - 1
        );
        if (findPrevQuestion) {
          if (this.canChooseQuestion(findPrevQuestion, false)) {
            return "";
          }
        }
      }

      if (this.QuestionData.ItemIndex > questionItemIndex) {
        return "";
      }

      if (this.QuestionData.ItemIndex == questionItemIndex - 1) {
        if (this.canGoToNextQuestion) {
          return "";
        } else {
          return "opacity: 0.5; cursor: default;";
        }
      }

      if (this.QuestionData.ItemIndex < questionItemIndex) {
        return "opacity: 0.5; cursor: default;";
      }

      return "";
    },

    canChooseQuestion(question, checkPrev) {
      if (!this.QuestionData.CanModifyAfterReady) {
        return false;
      }

      if (question.ItemIndex != 0 && checkPrev) {
        let findPrevQuestion = this.QuestionsList.find(
          (x) => x.ItemIndex == question.ItemIndex - 1
        );
        if (findPrevQuestion) {
          if (this.canChooseQuestion(findPrevQuestion, false)) {
            return true;
          }
        }
      }

      if (question.Question_QQuestion["TQuestion.name"] == "Текстовый") {
        if (
          this.getAnswer(question.Question_QQuestion.AQuestion[0].id) == null ||
          this.getAnswer(question.Question_QQuestion.AQuestion[0].id)
            .text_value == ""
        ) {
          return false;
        } else {
          return true;
        }
      }

      let c = 0;
      for (let i of question.Question_QQuestion.AQuestion) {
        let find = this.SelectedAnswers.findIndex((item) => item.id === i.id);

        if (find != -1) {
          c++;
        }
      }

      if (c == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.TimerCount = this.StartTimeValue;
    this.SelectedAnswers = this.AnswersList;
  },
};
</script>

<style scoped>
.CardCheckUser {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background: rgba(31, 30, 72, 0.8);
  border-radius: 20px;
  width: auto;
  height: fit-content;
  position: relative;
  overflow: hidden;
}
.ButtonColor {
  display: flex;
  justify-content: center;

  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  width: 235px;
  height: fit-content;
}
.ButtonBorder {
  display: flex;
  justify-content: center;

  width: 235px;
  height: fit-content;
}
.QuestInfo {
  display: flex;
  flex-direction: column;
}
.UpperContent {
  display: flex;
  flex-direction: column;
}
.BottomContent {
  display: flex;
  flex-direction: column;
}
.SetupTextPoint {
  color: #ffffff;
  font-size: 20px;
  font-weight: 250;
  line-height: 24.38px;
}
.SetupTextPoint.TextSizeAuto {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.SetupTextPoint.TextSizeAuto.TasksNum {
  display: flex;
  position: relative;

  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;

  margin: 0;

  color: #ffffff;

  font-size: 16px;
  font-weight: 600;
}
.SetupVariableText {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(144, 202, 249, 0.5);

  margin-top: 30px;
  margin-bottom: 15px;
}
.SetupVariableText.VariableTextSize {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.AllTestTasks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}
.LeftFloat {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px;
}
.RightFloat {
  display: flex;
  flex-direction: column;
  width: 45%;
  /* background: #1F1E48; */
  border-radius: 20px;
  padding: 30px;
  justify-content: space-between;
}
.PrevBackButtons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.PrevBackButtonsMobile {
  display: none;
}
.ButtonBorderEnd {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
}
.ButtonBorderEnd::before {
  background: linear-gradient(315deg, #ff7676 0%, #f54ea2 100%);
}
.AddVariable {
  margin-bottom: 30px;
}
.QuestionField {
  display: flex;
  background: var(
    --input,
    linear-gradient(
      269deg,
      rgba(41, 37, 88, 0.54) 0%,
      rgba(38, 35, 83, 0.87) 40%,
      rgba(40, 38, 89, 0.5) 100%
    )
  );
  border-radius: 4px;
  border-style: solid;
  border-color: #413e74;
  border-width: 1px;
  padding: 12px;
  width: auto;
  height: 48px;
  top: 30px;
  margin-bottom: 15px;

  color: #667080;
  text-align: left;
  font: 400 20px/22px "Inter", sans-serif;
}

/*Чекбокс*/

.SetupCheck {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  gap: 20px;
}

.SetupCheckText {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.real-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: #f8f7f700;
  border: 1px solid #312e57;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-radio {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #f8f7f700;
  border: 1px solid #312e57;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/*Состояния чекбокса*/

.real-checkbox:checked + .custom-checkbox,
.real-checkbox:checked + .custom-radio {
  background-image: url("./../../assets/img/Setup/vector.svg");
  background-position: center;
}

.CardAddAnswer {
  display: flex;
  flex-direction: column;
}

.PossibleAnswer {
  display: flex;
  background: rgba(31, 30, 72, 0.8);
  opacity: 100;
  border-radius: 4px;
  padding: 12px;
  width: auto;
  height: 48px;
  top: 30px;
  margin-bottom: 15px;

  border: none; /* Убираем границы формы */
  outline: none; /* Убираем обводку формы */
  border-bottom: 1px solid #413e74; /* Добавляем нижнюю границу */

  color: #667080;
  text-align: left;
  font: 400 20px/22px "Inter", sans-serif;
}

.TaskVar {
  cursor: pointer;
  height: 32px;
  position: relative;
  width: 38px;
}

.InactiveCell.ActiveCell {
  background-color: #b434b5;
}
.InactiveCell {
  display: flex;
  position: relative;

  height: 32px;
  width: 36px;

  justify-content: center;
  align-items: center;

  background-color: #302d57;
  border-radius: 10px;
}

.InactiveCell.HalfActiveCell {
  border: 1px solid;
  border-color: #b434b5;
}
</style>
<style scoped>
@media (max-width: 479px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .CardCheckUser {
    display: flex;
    flex-direction: column-reverse;
  }
  .SetupTextPoint.TextSizeAuto {
    font-size: 16px;
    width: fit-content;
  }
  .SetupVariableText {
    font-size: 14px;
    width: fit-content;
  }
  .BottomContent,
  .UpperContent,
  .QuestInfo {
    width: fit-content;
  }
  .LeftFloat {
    margin: 20px;
    width: auto;
  }
  .TimeResult {
    width: fit-content;
  }
  .SetupTextPointN.TextSizeAutoN {
    width: fit-content;
  }
  .PrevBackButtons {
    display: none;
  }
  .PrevBackButtonsMobile {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
  }
  .ButtonColor,
  .ButtonBorder {
    width: auto;
  }
  .RightFloat {
    width: 100%;
    gap: 16px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .CardCheckUser {
    display: flex;
    flex-direction: column-reverse;
  }
  .SetupTextPoint.TextSizeAuto {
    font-size: 16px;
    width: fit-content;
  }
  .SetupVariableText {
    font-size: 14px;
    width: fit-content;
  }
  .BottomContent,
  .UpperContent,
  .QuestInfo {
    width: fit-content;
  }
  .LeftFloat {
    margin: 20px;
    width: auto;
  }
  .TimeResult {
    width: fit-content;
  }
  .SetupTextPointN.TextSizeAutoN {
    width: fit-content;
  }
  .PrevBackButtons {
    display: none;
  }
  .PrevBackButtonsMobile {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
  }
  .ButtonColor,
  .ButtonBorder {
    width: auto;
  }
  .RightFloat {
    width: 100%;
    gap: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .CardCheckUser {
    display: flex;
    flex-direction: column-reverse;
  }
  .SetupTextPoint.TextSizeAuto {
    font-size: 16px;
    width: fit-content;
  }
  .SetupVariableText {
    font-size: 14px;
    width: fit-content;
  }
  .BottomContent,
  .UpperContent,
  .QuestInfo {
    width: fit-content;
  }
  .LeftFloat {
    margin: 20px;
    width: auto;
  }
  .TimeResult {
    width: fit-content;
  }
  .SetupTextPointN.TextSizeAutoN {
    width: fit-content;
  }
  .PrevBackButtons {
    display: none;
  }
  .PrevBackButtonsMobile {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
  }
  .ButtonColor,
  .ButtonBorder {
    width: auto;
  }
  .RightFloat {
    width: 100%;
    gap: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .HiddentProjectsList {
    width: auto;
    margin: 70px 30px;
    padding: 0%;
  }
  .RightFloat {
    width: 55%;
    gap: 16px;
  }
}

@media (min-width: 1400px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
}
</style>
