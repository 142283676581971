<template>
  <div class="CardCheckResult">
    <div class="LeftFloat">
      <div class="QuestInfo">
        <template v-if="minimalPercentOfSuccess <= getTestProgressPercent">
          <span class="SetupTextPoint TextSizeAuto"
            >Вы сдали! Правильных ответов {{ getTestProgressPercent }}%</span
          >
        </template>
        <template v-else>
          <span class="SetupTextPoint TestNotDone"
            >Увы, вы не сдали! Правильных ответов
            {{ getTestProgressPercent }}%</span
          >
        </template>
        <span class="SetupVariableText"
          >Минимальный проходной процент: {{ minimalPercentOfSuccess }}%</span
        >
      </div>
      <div class="TimeResult">
        <span class="SetupTextPointN TextSizeAuto">Время прохождения</span>
        <span class="SetupTextPoint ">{{ getHumanTime }}</span>
      </div>
      <span class="SetupTextPointN TextSizeAutoN">Результаты тестирования</span>

      <div class="AllTestTasks">
        <div
          class="TaskVar"
          v-for="(question, indx) in ResultQuestionsList"
          :key="`${indx}${question.id}`"
        >
          <div :class="`InactiveCell ${question.correct ? 'ActiveCell' : ''}`">
            <span class="SetupTextPoint TextSizeAuto TasksNum">{{
              indx + 1
            }}</span>
          </div>
        </div>
      </div>

      <div class="PrevBackButtons">
        <ButtonGradientBorder
          class="ButtonColor"
          ButtonText="Запустить еще попытку"
          @click.native="StartNewTest"
        />
        <router-link to="/beta/profile">
          <ButtonGradient
            class="ButtonBorder"
            ButtonText="Вернуться в профиль"
          />
        </router-link>
      </div>
      <div class="PrevBackButtonsMobile">
        <ButtonGradientBorder class="ButtonColor" ButtonText="Заново" />
        <router-link to="/beta/profile">
          <ButtonGradient
            class="ButtonBorder"
            ButtonText="Вернуться в профиль"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

export default {
  props: {
    ResultCardData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    ResultQuestionsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    StartNewTest: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
  },
  components: {
    ButtonGradient,
    ButtonGradientBorder,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    getHumanTime() {
      return this.ResultCardData == null
        ? "00:00:00"
        : this.ResultCardData.time;
    },

    getCorrectQuestions() {
      if (this.ResultCardData == null) {
        return 0;
      }

      let c = 0;
      for (let q of this.ResultQuestionsList) {
        if (q.correct) {
          c++;
        }
      }

      return c;
    },

    getTestProgressPercent() {
      if (this.ResultQuestionsList.length == 0) {
        return 0;
      }
      return Math.round(
        (this.getCorrectQuestions / this.ResultQuestionsList.length) * 100
      );
    },

    minimalPercentOfSuccess() {
      return this.ResultCardData?.success_limit == null
        ? 100
        : this.ResultCardData?.success_limit;
    },
  },
};
</script>

<style scoped>
.CardCheckResult {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background: rgba(31, 30, 72, 0.8);
  border-radius: 20px;
  width: auto;
  height: fit-content;
  position: relative;
  overflow: hidden;
}
.PrevBackButtonsMobile {
  display: none;
}

.ButtonColor {
  display: flex;
  justify-content: center;

  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  width: 235px;
  height: fit-content;
}
.ButtonBorder {
  display: flex;
  justify-content: center;

  width: 235px;
  height: fit-content;
}
.TimeResult {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.BottomContent,
.UpperContent,
.QuestInfo {
  display: flex;
  flex-direction: column;
}
.SetupTextPoint {
  color: #ffffff;
  font-size: 16px;
  font-weight: 250;
}
.SetupTextPointN {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}
.SetupTextPointN.TextSizeAutoN {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  margin: 15px 0px;
}

.SetupTextPoint.TextSizeAuto {
  color: #1dd185;
  font-size: 20px;
  font-weight: 700;
}

.SetupTextPoint.TestNotDone {
  color: #eb5757;
  font-size: 20px;
  font-weight: 700;
}

.SetupTextPoint.TextSizeAuto.TasksNum {
  display: flex;
  position: relative;

  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;

  margin: 0;

  color: #ffffff;

  font-size: 16px;
  font-weight: 600;
}

.SetupVariableText {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(144, 202, 249, 0.5);
  margin-bottom: 60px;
}

.AllTestTasks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 30px;
}

.LeftFloat {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px;
}

.PrevBackButtons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.TaskVar {
  height: 32px;
  position: relative;
  width: 38px;
}

.InactiveCell.ActiveCell {
  background-color: #1dd185;
}

.InactiveCell {
  display: flex;
  position: relative;

  height: 32px;
  width: 36px;

  justify-content: center;
  align-items: center;

  background-color: #eb5757;
  border-radius: 10px;
}
</style>

<style scoped>
@media (max-width: 479px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .CardCheckUser {
    display: flex;
    flex-direction: column-reverse;
  }
  .SetupTextPoint.TextSizeAuto {
    font-size: 16px;
    width: fit-content;
  }
  .SetupVariableText {
    font-size: 14px;
    width: fit-content;
  }
  .BottomContent,
  .UpperContent,
  .QuestInfo {
    width: fit-content;
  }
  .LeftFloat {
    margin: 20px;
    width: auto;
  }
  .TimeResult {
    width: fit-content;
  }
  .SetupTextPointN.TextSizeAutoN {
    width: fit-content;
  }
  .PrevBackButtons {
    display: none;
  }
  .PrevBackButtonsMobile {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
  }
  .ButtonColor,
  .ButtonBorder {
    width: auto;
  }
  .RightFloat {
    width: 100%;
    gap: 16px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .CardCheckUser {
    display: flex;
    flex-direction: column-reverse;
  }
  .SetupTextPoint.TextSizeAuto {
    font-size: 16px;
    width: fit-content;
  }
  .SetupVariableText {
    font-size: 14px;
    width: fit-content;
  }
  .BottomContent,
  .UpperContent,
  .QuestInfo {
    width: fit-content;
  }
  .LeftFloat {
    margin: 20px;
    width: auto;
  }
  .TimeResult {
    width: fit-content;
  }
  .SetupTextPointN.TextSizeAutoN {
    width: fit-content;
  }
  .PrevBackButtons {
    display: none;
  }
  .PrevBackButtonsMobile {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
  }
  .ButtonColor,
  .ButtonBorder {
    width: auto;
  }
  .RightFloat {
    width: 100%;
    gap: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .CardCheckUser {
    display: flex;
    flex-direction: column-reverse;
  }
  .SetupTextPoint.TextSizeAuto {
    font-size: 16px;
    width: fit-content;
  }
  .SetupVariableText {
    font-size: 14px;
    width: fit-content;
  }
  .BottomContent,
  .UpperContent,
  .QuestInfo {
    width: fit-content;
  }
  .LeftFloat {
    margin: 20px;
    width: auto;
  }
  .TimeResult {
    width: fit-content;
  }
  .SetupTextPointN.TextSizeAutoN {
    width: fit-content;
  }
  .PrevBackButtons {
    display: none;
  }
  .PrevBackButtonsMobile {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
  }
  .ButtonColor,
  .ButtonBorder {
    width: auto;
  }
  .RightFloat {
    width: 100%;
    gap: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  .HiddentProjectsList {
    width: auto;
    margin: 70px 30px;
    padding: 0%;
  }
  .RightFloat {
    width: 100%;
    gap: 16px;
  }
}

@media (min-width: 1400px) {
  #TestUserPage {
    position: relative;

    display: flex;
    flex-direction: column;
  }
}
</style>
